import React from "react";
import "./../GetaFreeOfferHeader/GetaFreeOfferHeader.css";
function GetaFreeOfferHeader() {
  return (
    <div className="main_div_contactusheader1">
      <p className="headercontactus_para1">GET MY CASH OFFER</p>
    </div>
  );
}

export default GetaFreeOfferHeader;
