import React from "react";
import "./../HighestCashoffer/HighestCashoffer.css";
import icon1 from "./../../../Assets/highestcashoffer.svg";
import icon2 from "./../../../Assets/highestcashoffer1.svg";
import icon3 from "./../../../Assets/highestcashoffer3.svg";
import icon4 from "./../../../Assets/highestcashoffer4.svg";
import icon5 from "./../../../Assets/highestcashoffer5.svg";
function HighestCashoffer() {
  return (
    <div className="main_div_highest_cashoffer">
      <div className="container">
        <div className="row">
          <div className="col-lg col-md-3 col-sm-12 col-12 ">
            <div className="d-flex justify-content-center">
              <img src={icon1} className="icon_highestcashoffer" />
            </div>
            <p className="para_highest_offer">Highest Cash Offer</p>
          </div>

          <div className="col-lg col-md-3 col-sm-12 col-12 ">
            <div className="d-flex justify-content-center">
              <img src={icon2} className="icon_highestcashoffer" />
            </div>
            <p className="para_highest_offer">No Repairs Needed</p>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
            <div className="d-flex justify-content-center">
              <img src={icon3} className="icon_highestcashoffer" />
            </div>
            <p className="para_highest_offer">
              Virtual Consultations Available
            </p>
          </div>

          <div className="col-lg col-md-3 col-sm-12 col-12 ">
            <div className="d-flex justify-content-center">
              <img src={icon4} className="icon_highestcashoffer" />
            </div>
            <p className="para_highest_offer">No Fees or Commission</p>
          </div>

          <div className="col-lg col-md-12 col-sm-12 col-12 ">
            <div className="d-flex justify-content-center">
              <img src={icon5} className="icon_highestcashoffer" />
            </div>
            <p className="para_highest_offer">Purchase In Any Condition</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HighestCashoffer;
