import React from "react";
import "./../SellMyLandFast/SellMyLandFast.css";
import icon1 from "./../../../Assets/card1.png";
import icon2 from "./../../../Assets/card2.png";
import icon3 from "./../../../Assets/card3.png";
function SellMyLandFast() {
  return (
    <div className="main_div_SellMyLandFast">
      <div className="container">
        <p className="headin_SellMyLandFast">
          How Do I Sell My Arizona Land Fast For Cash
        </p>
        <p className="para_SellMyLandFast">
          Every day, we get asked, “How can I sell my Arizona Land for cash?” We let our
          clients know that we have a simple solution. You can sell in three
          easy steps. No more waiting for a buyer or a reasonable offer. No more
          sale signs. No hassle. Just sell your Arizona Land fast and trouble-free. So,
          how do we do it?
        </p>

        <p className="para_SellMyLandFast_below_heading">
          Three <b>Easy</b> Steps
        </p>
        <div className="main_div_cards_easysteps">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="green_inside_div">
                <p className="heading_green_card">
                  Schedule a Free Home Consultation
                </p>
              </div>

              <div className="main_div_card_greenSellMyLandFast">
                <div className="d-flex justify-content-center">
                  <img src={icon1} className="icon_sell_myhousefast" />
                </div>
                <p className="para_sell_myjouse_fast">
                  We will learn about your needs and assess your Arizona Land by walking
                  through the property with you, explaining the process as we go
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="green_inside_div">
                <p className="heading_green_card">Get My Cash Offer Today</p>
              </div>

              <div className="main_div_card_greenSellMyLandFast">
                <div className="d-flex justify-content-center">
                  <img src={icon2} className="icon_sell_myhousefast" />
                </div>
                <p className="para_sell_myjouse_fast">
                  We’ll do our research and present you with an offer at a fair
                  market price.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="green_inside_div">
                <p className="heading_green_card">Close At Your Convenience</p>
              </div>

              <div className="main_div_card_greenSellMyLandFast">
                <div className="d-flex justify-content-center">
                  <img src={icon3} className="icon_sell_myhousefast" />
                </div>
                <p className="para_sell_myjouse_fast">
                  We buy your house. You get paid. We can close in as little as
                  two days.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellMyLandFast;
