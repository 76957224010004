import React from 'react'
import "./../PrivacyPolicyHeader/PrivacyPolicyHeader.css"
function PrivacyPolicyHeader() {
  return (
    <div className='main_div_privacyheader'>
    <p className='headerprivacy_para'>PRIVACY POLICY</p>
  </div>
  )
}

export default PrivacyPolicyHeader
