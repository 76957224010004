import React, { useEffect, useState } from "react";
import "./../ScheduleFreeConsultation/ScheduleFreeConsultation.css";
import phone from "./../../../Assets/phonecc.png";
import { Link } from "react-router-dom";
function ScheduleFreeConsultation() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    scrollToTop();
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  
  return (
    <div className="main_div_schedukle_consultant">
      <div className="container">
        <div className="main_div_schedule">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-6 col-12">
            <p className="heading_consultant">
              Schedule A Free Consultation Today
            </p>
            <p className="para_consultant">
              We can close in less than 72 hours if needed
            </p>
        
              <button className="btn_get_my_cash_offer_consultant" onClick={scrollToTop}>
                Get my Cash Offer{" "}
              </button>
          
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="d-flex justify-content-center">
              <img src={phone} className="phone_style" />
            </div>

            <p className="para_phone_number_consultant">480-530-0333</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleFreeConsultation;
