import React, { useEffect, useState } from "react";
import "./../TopDollarForYourLand/TopDollarForYourLand.css";
import icon from "./../../../Assets/topdollar1icon.svg";
import icon1 from "./../../../Assets/topdollar2icon.svg";
import { Link } from "react-router-dom";
function TopDollarForYourLand() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    scrollToTop();
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);


  return (
    <>
      <div className="main_div_TopDollarForYourLand">
        <div className="container">
          <div className="d-flex justify-content-center">
            <p className="heading_TopDollarForYourLand">
              TOP Dollar for Your LAND
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <p className="para_TopDollarForYourLand">
              Selling a Arizona Land with a Realtor is not always the best option for
              everyone.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <p className="para_TopDollarForYourLand">
              There are plenty of headaches when working with a licensed real
              estate agent to sell your land.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <p className="para_TopDollarForYourLand">
              There is lots of paperwork, months of uncertainty, fees, and the
              inconvenience of showing your Arizona Land to multiple people at odd hours
              of the day and night.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            
              <button className="BTN_CASH_OFFER_TOP" onClick={scrollToTop}>
                GET MY CASH OFFER NOW
              </button>
        
          </div>

          <p className="wondering_heading">
            If you’re wondering, yes, there is a better way to sell your land…
          </p>
          <div className="row">
            <div className="col-l-6 col-md-6 col-sm-6 col-12">
              <button className="btn_no_fees">No fees or commissions</button>

              <p className="wondering_heading_below_para">
                With Quick Cash For Land, there are no realtor commissions or
                closing fees to pay.
              </p>

              <button className="btn_no_repair">No repairs or cleaning</button>

              <p className="wondering_heading_below_para">
                Forget cleaning up your Arizona Land or spending money to repair it in
                order to show it to potential buyers, we’ll buy your land
                exactly the way it is.
              </p>

              <button className="btn_less_paperwork">Less paperwork</button>
              <p className="wondering_heading_below_para">
                When working with agents, you have to deal with mountains of
                paperwork. At Quick Cash For Land, we have one simple easy to
                understand sales agreement.
              </p>

              <button className="btn_no_ling">No long-term commitments</button>
            </div>
            <div className="col-l-6 col-md-6 col-sm-6 col-12">
              <img src={icon} className="icon_style_top" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg- col-md-6 col-sm-6 col-12">
              <img src={icon1} className="top_right_icon_style" />
            </div>
            <div className="col-lg- col-md-6 col-sm-6 col-12">
              <p className="parafor_top_right_icon">
                The traditional Arizona Land selling experience can take months to
                complete from beginning to end, on top of having to pay Realtor
                commissions. With Quick Cash For Land, it’s much easier!
              </p>
              <p className="parafor_top_right_icon1">
                We’re not listing your lands… we’re actually the ones buying
                your land. Since we pay cash, we’re able to close quickly… or on
                your schedule. Like we’ve mentioned, when you work with us there
                are no fees… like there are when you list your Arizona Land with an
                agent.
              </p>
              <p className="parafor_top_right_icon1">
                Once you send us your info, we’re usually able to make you a
                fair all-cash offer within 24 hours. From there, we can close as
                quickly as 7 days… or on your schedule (sometimes we can have a
                check in your hand the very same day!).
              </p>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* <div className="main_div_white_line1"></div> */}
      </>
    </>
  );
}

export default TopDollarForYourLand;
