import React from 'react'
import "./../ContactUsHeader/ContactUsHeader.css"
function ContactUsHeader() {
  return (
    <div className='main_div_contactusheader'>
      <p className='headercontactus_para'>CONTACT US</p>
    </div>
  )
}

export default ContactUsHeader
