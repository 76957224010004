import React from "react";
import "./../SellingWithUs/SellingWithUs.css";
import selling from "./../../../Assets/Group 233.svg";
function SellingWithUs() {
  return (
    <>
      <div className="main_div_selling_with_us">
        <div className="container">
          <div className="d-flex justify-content-center">
            <p className="para_selling_with_us">
              <b>Selling</b> With Us
            </p>
          </div>

          <div className="d-flex justify-content-center">
            <p className="para_selling_with_us">
              <b>VS.</b>
            </p>
          </div>

          <div className="d-flex justify-content-center">
            <p className="para_selling_with_us">
              <b>Listing</b> With A Realtor
            </p>
          </div>
        </div>
      </div>

      <div className="main_listing">
        <div className="container">
          <img src={selling} className="selling_with_us_icon" />
        </div>
      </div>
    </>
  );
}

export default SellingWithUs;
