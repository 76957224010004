import React from "react";
import "./..//WemakeitEasy/WemakeitEasy.css";
import wemakeiteasyicon from "./../../../Assets/wemakeiteasyiconnnnnnnnn.svg";
import bluediv from "./../../../Assets/bluedivvvvvvv.png";
function WemakeitEasy() {
  return (
    <div className="main_WemakeitEasy">
      <div className="container">
        <p className="heading_WemakeitEasy">
          We Make It Easy to Sell Your Land Fast for Cash
        </p>
        <p className="para_WemakeitEasy">
          Are you looking to sell your Land fast? As top nationwide Land buyers we can
          pay cash for your Land in any condition, or situation.
        </p>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <p className="heading_benefits">
              Here’s how you benefit when working with Quick Cash for Land:
            </p>
            <div className="d-flex flex-row">
              <img src={bluediv} className="blueidv_style" />
              <p className="heading_blue_div">
                <b>Sell as-is –</b> there’s no need to make any repairs or
                renovations.
              </p>
            </div>
            <div className="d-flex flex-row">
              <img src={bluediv} className="blueidv_style" />
              <p className="heading_blue_div">
                <b>Sell without a Realtor –</b> There are no commissions or fees
                to pay either.
              </p>
            </div>

            <div className="d-flex flex-row">
              <img src={bluediv} className="blueidv_style" />
              <p className="heading_blue_div">
                <b>Get a fair cash offer –</b> We guarantee no low-ball offers.
              </p>
            </div>

            <div className="d-flex flex-row">
              <img src={bluediv} className="blueidv_style" />
              <p className="heading_blue_div">
                <b>Flexible –</b> Sell right away if you’re in a hurry or choose
                a closing date that works for you.
              </p>
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-12"></div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <img src={wemakeiteasyicon} className="wemakeiteasy_style_icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WemakeitEasy;
