import React from "react";
import "./../Footer/Footer.css";
import fb from "./../../../Assets/fb.png";
import insta from "./../../../Assets/insta.png";
import linkedin from "./../../../Assets/linkedi.png";
import twitter from "./../../../Assets/twitter.png";
import arrow from "./../../../Assets/arrownews.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="main_div_footer">
      <div className="row m-0 p-0">
        <div className="col-lg-1 col-md-1 col-sm-12 col-12"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <p className="para_overview">Overview</p>
              <p className="para_overview_below">
                Quick cash for Land buys nationwide Land in as-is condition. Our
                stress-free process is proven. We work one-on-one with each
                landowner to create a unique custom solution; despite the
                circumstances.
              </p>
              <div className="d-flex flex-row">
                <a
                  href="https://www.facebook.com/people/QuickCash4land/61558281879086"
                  target="_blank"
                >
                  <img src={fb} className="icon_style_social" alt="Facebook" />
                </a>
                {/* <a href="https://www.instagram.com/" target="_blank">
                  <img
                    src={insta}
                    className="icon_style_social"
                    alt="Instagram"
                  />
                </a> */}
                <a
                  href="https://www.linkedin.com/in/jennifer-hoffiz-671ba2132/A"
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    className="icon_style_social"
                    alt="LinkedIn"
                  />
                </a>
                {/* <a href="https://twitter.com/" target="_blank">
                  <img
                    src={twitter}
                    className="icon_style_social"
                    alt="Twitter"
                  />
                </a> */}
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-6 col-12">
              <p className="para_overview">Quick Links</p>
              <Link to="/">
                {" "}
                <p className="para_overview_below1">Home</p>
              </Link>
              <Link to="/whoweare">
                {" "}
                <p className="para_overview_below1">Who we are</p>
              </Link>
              {/* <Link to="/">
              <p className="para_overview_below1">About Us</p>
            </Link> */}
              <Link to="/blogs">
                <p className="para_overview_below1">Blogs</p>
              </Link>
              <Link to="/privacypolicy">
                {" "}
                <p className="para_overview_below1">Privacy Policy</p>
              </Link>
              <Link to="/termsandcondition">
                {" "}
                <p className="para_overview_below1">Terms and Condition</p>
              </Link>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-12">
              <p className="para_overview">Our Support</p>
              <Link to="/contactus">
                {" "}
                <p className="para_overview_below1">Contact Us</p>
              </Link>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <p className="para_overview">Newsletter</p>

              <p className="para_overview_below1">
                Subscribe Our Newsletter & Get More
              </p>
              <input
                className="input_style_newsletter"
                placeholder="info@gmail.com"
              />
              <div className="d-flex flex-row">
                <p className="para_overview_below1">Subscribe Now</p>
                <img src={arrow} className="arrow_style" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-12 col-12"></div>
      </div>
    </div>
  );
}

export default Footer;
