import React, { useEffect, useState } from "react";
import Navbar from "./../Navbar/Navbar";
import Sell_Your_Land from "../Sell_Your_Land/Sell_Your_Land";
import HighestCashoffer from "../HighestCashoffer/HighestCashoffer";
import SellMyLandFast from "../SellMyLandFast/SellMyLandFast";
import TopDollarForYourLand from "../TopDollarForYourLand/TopDollarForYourLand";
import ScheduleFreeConsultation from "../ScheduleFreeConsultation/ScheduleFreeConsultation";
import SellingWithUs from "../SellingWithUs/SellingWithUs";
import WhatOurClientSay from "../WhatOurClientSay/WhatOurClientSay";
import Footer from "../Footer/Footer";
function HomePageMerge() {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
      scrollToTop();
      return () => {
        window.removeEventListener("scroll", toggleVisibility);
      };
    }, []);
  return (
    <div>
      <Navbar />
      <Sell_Your_Land />
      <HighestCashoffer />
      <SellMyLandFast />
      <TopDollarForYourLand />
      <ScheduleFreeConsultation />
      <SellingWithUs />
      {/* <WhatOurClientSay /> */}
      <Footer />
      <>
        {isVisible && (
          <button onClick={scrollToTop} className="scroll-to-top-button">
            <span>&uarr;</span>
          </button>
        )}
      </>
    </div>
  );
}

export default HomePageMerge;
