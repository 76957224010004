import React, { useState } from "react";
import "./../Sell_Your_Land/Sell_Your_Land.css";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import NotificationDisplay from "../../NotificationDisplay/NotificationDisplay";
import Loader from "../../Loader/Loader";
import bg from "./../../../Assets/bg land.png";
function Sell_Your_Land() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    phone: "",
    email: "",
  });
  const navigate = useNavigate();
  const [notificationMessage, setNotificationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Make API call using Axios
      // const response = await axios.post("your_api_endpoint_here", formData);

      // Save input data to local storage
      localStorage.setItem("formData", JSON.stringify(formData));
      setLoading(false);
      setNotificationMessage("Please fill get my cash offer form also!");
      setTimeout(() => {
        setNotificationMessage("");
        navigate("/getafreeoffer");
      }, 4000);
      // Navigate to another page after successful submission
    } catch (error) {
      setLoading(false);
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <>
        <NotificationDisplay message={notificationMessage} />
      </>
      <div className="main_div_sell_your_land">
        <div className="row">
          <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
          <div className="col-lg-10 col-md-10 col-sm-10 col-10">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                <p className="heading_sell_yourhouse">
                  We'll Buy Your Arizona Land For Cash!
                </p>
                <p className="desp_sell_yourhouse">
                  We buy LAND in MOST conditions. No realtors, no waiting, no
                  worrying! Get your No-Obligation All Cash Offer Today!
                </p>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                <div className="d-flex justify-content-end">
                  <img src={bg} className="bg_style_land" />
                  {/* <div className="form_main_div_sell">
                  
                  <form onSubmit={handleSubmit}>
                    <textarea
                      placeholder="Address*"
                      className="address_input"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    ></textarea>
                    <input
                      placeholder="Phone*"
                      className="phone_input"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                    <input
                      placeholder="Email*"
                      className="phone_input"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      required
                    />
                    <button className="btn_get_a_free_offer_blue">
                      
                      {loading ? <Loader /> : "Get my Cash Offer"}
                    </button>
                  </form>
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
        </div>
      </div>
      <div className="main_div_white_line">
        <div className="main_div_form_upper">
          <p className="para_for_form">
            {" "}
            Submit your information and We will send you an offer either via
            email or by snail mail!
          </p>
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="main_div_form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <input
                      className="input_style_form_get_offer"
                      placeholder="First Name*"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <input
                      className="input_style_form_get_offer"
                      placeholder="Last Name*"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                      type="text"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <input
                      className="input_style_form_get_offer1"
                      placeholder="Phone number*"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <input
                      className="input_style_form_get_offer1"
                      placeholder="Email*"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <input
                      className="input_style_form_get_offer1"
                      placeholder="Land Address or APN*"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <input
                  type="checkbox"
                  name="checkbox"
                  id="checkbox_id"
                  value="value"
                  className="checkbox_input_form"
                  required
                />
                <label for="checkbox_id" className="checkbox_label">
                  Yes, I Agree
                </label>
                <p className="para_below_checkbox">
                  By submitting this form and signing up for texts, you consent
                  to receive marketing text messages from Quick Cash 4 Land at
                  the number provided. Consent is not a condition of purchase.
                  Msg & data rates may apply. Unsubscribe at any time by
                  replying STOP
                </p>
                <div className="d-flex justify-content-center">
                  <button className="get_free_offer_btn_form" type="submit">
                    Get My Offer Now!
                  </button>
                </div>
                <p className="para_below_btn">
                  Provided information is secured and Privacy Policy applied.
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sell_Your_Land;
