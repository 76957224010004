
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePageMerge from './Components/Homepage/HomePageMerge/HomePageMerge';
import WhoWeAreMerge from './Components/WhoWeArePage/WhoWeAreMerge/WhoWeAreMerge';
import ContactUsPageMerge from './Components/ContactUsPage/ContactUsPageMerge/ContactUsPageMerge';
import GetaFreeOfferPageMerge from './Components/GetaFreeOfferPage/GetaFreeOfferPageMerge/GetaFreeOfferPageMerge'
import PrivacypolicypageMerge from './Components/Privacypolicypage/PrivacypolicypageMerge/PrivacypolicypageMerge'
import TermsandConditionMerge from './Components/TermsandCondition/TermsandConditionMerge/TermsandConditionMerge'
import SignIn from './Components/SignInPage/SignIn'
import Blogs from './Components/Blogs/Blogs'
import BlogPostOpen from './Components/BlogPostOpen/BlogPostOpen'
import Blogcategory from './Components/BlogCategory/Blogcategory'
import BlogTag from './Components/BlogTag/BlogTag'
import AddBlog from './Components/Dashboard/AddBlog'


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePageMerge />} />
        <Route path="/whoweare" element={<WhoWeAreMerge />} />
        <Route path="/contactus" element={<ContactUsPageMerge />} />
        <Route path="/getafreeoffer" element={<GetaFreeOfferPageMerge />} />
        <Route path="/privacypolicy" element={<PrivacypolicypageMerge />} />
        <Route path="/termsandcondition" element={<TermsandConditionMerge />} />

        <Route path="/signin" element={<SignIn />} />
        <Route path="/dashboard" element={<AddBlog />} />

        <Route exact path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id/:title" element={<BlogPostOpen />} />
        <Route path="/category/:id" element={<Blogcategory />} />
        <Route path="/tags/:id" element={<BlogTag />} />
      </Routes>
    </Router>
  );
}

export default App;
