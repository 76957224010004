import React, { useEffect, useState } from "react";
import "./../GetaFreeOfferForm/GetaFreeOfferForm.css";
import Loader from "./../../Loader/Loader";
import NotificationDisplay from "./../../NotificationDisplay/NotificationDisplay";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function GetaFreeOfferForm() {
  const [loading, setLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const statesList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [selectedState, setSelectedState] = useState("");

  const handleSelect = (event) => {
    setSelectedState(event.target.value);
  };

  const counties = [
    "Alameda",
    "Alpine",
    "Amador",
    "Butte",
    "Calaveras",
    "Colusa",
    "Contra Costa",
    "Del Norte",
    "El Dorado",
    "Fresno",
    "Glenn",
    "Humboldt",
    "Imperial",
    "Inyo",
    "Kern",
    "Kings",
    "Lake",
    "Lassen",
    "Los Angeles",
    "Madera",
    "Marin",
    "Mariposa",
    "Mendocino",
    "Merced",
    "Modoc",
    "Mono",
    "Monterey",
    "Napa",
    "Nevada",
    "Orange",
    "Placer",
    "Plumas",
    "Riverside",
    "Sacramento",
    "San Benito",
    "San Bernardino",
    "San Diego",
    "San Francisco",
    "San Joaquin",
    "San Luis Obispo",
    "San Mateo",
    "Santa Barbara",
    "Santa Clara",
    "Santa Cruz",
    "Shasta",
    "Sierra",
    "Siskiyou",
    "Solano",
    "Sonoma",
    "Stanislaus",
    "Sutter",
    "Tehama",
    "Trinity",
    "Tulare",
    "Tuolumne",
    "Ventura",
    "Yolo",
    "Yuba",
  ];

  const [selectedCounty, setSelectedCounty] = useState("");

  const handleCountyChange = (e) => {
    setSelectedCounty(e.target.value);
  };

  const cities = [
    "New York",
    "Los Angeles",
    "Chicago",
    "Houston",
    "Phoenix",
    "Philadelphia",
    "San Antonio",
    "San Diego",
    "Dallas",
    "San Jose",
    "Austin",
    "Jacksonville",
    "San Francisco",
    "Columbus",
    "Fort Worth",
    "Indianapolis",
    "Charlotte",
    "Seattle",
    "Denver",
    "Washington",
    "Boston",
  ];

  const [selectedCity, setSelectedCity] = useState("");

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const [
    selectedOption_do_you_have_more_propety,
    setselectedOption_do_you_have_more_propety,
  ] = useState(null);

  const handleOptionChange = (option) => {
    setselectedOption_do_you_have_more_propety(option);
  };

  const [
    selectedOption_is_your_vacant_land,
    setselectedOption_is_your_vacant_land,
  ] = useState(null);

  const handle_is_your_vacant_landChange = (option) => {
    setselectedOption_is_your_vacant_land(option);
  };

  const [selectedOption_currently_live, setselectedOption_currently_live] =
    useState(null);

  const handle_currently_live_Change = (option) => {
    setselectedOption_currently_live(option);
  };

  const [
    selectedOption_more_important_to_you,
    setselectedOption_more_important_to_you,
  ] = useState(null);

  const handle_more_important_to_you_Change = (option) => {
    setselectedOption_more_important_to_you(option);
  };

  const [agree, setAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);

  const handleAgreeChange = () => {
    setAgree(!agree);
  };

  const handleAgree1Change = () => {
    setAgree1(!agree1);
  };

  const [acquisitionMethod, setAcquisitionMethod] = useState("");

  const handleCheckboxChange = (event) => {
    const selectedMethod = event.target.value;
    setAcquisitionMethod(selectedMethod);
  };

  const [zipcode, setzipcode] = useState("");

  const handlezipcodeChange = (event) => {
    setzipcode(event.target.value);
  };

  const [apnid, setapnid] = useState("");

  const handleapnidChange = (event) => {
    setapnid(event.target.value);
  };

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      setFormData(parsedFormData);
    }
  }, []);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      address: formData.address,
      phone: formData.phone,
      email: formData.email,
      aquire_property: acquisitionMethod,
      state: selectedState,
      county: selectedCounty,
      city: selectedCity,
      zip_code: zipcode,
      apn: apnid,
      more_than_one_property: selectedOption_do_you_have_more_propety,
      vacant_land_in_different_state: selectedOption_is_your_vacant_land,
      currently_live_in_different_state: selectedOption_currently_live,
      priority: selectedOption_more_important_to_you,
      agree: agree,
      agree1: agree1,
    };
    try {
  
      const response = await axios.post("/api/free-offers", payload);
      // Handle successful response, update state, display notification, etc.
      setLoading(false);
      setAcquisitionMethod("");
      setSelectedState("");
      setzipcode("");
      setapnid("");
      setselectedOption_do_you_have_more_propety("");
      setselectedOption_is_your_vacant_land("");
      setselectedOption_currently_live("");
      setselectedOption_more_important_to_you("");
      setAgree1("");
      setAgree("");
      localStorage.setItem("formData", "");
      setNotificationMessage("Form submitted successfully!");
      setTimeout(() => {
        setNotificationMessage("");
        navigate("/")
      }, 4000);

      // Example of how to handle response data:
      // console.log(response.data);
    } catch (error) {
      // Handle error, display error message, etc.
      setNotificationMessage("An error occurred.");
      setTimeout(() => {
        setNotificationMessage("");
      }, 4000);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="main_div_getafreeeoffer">
      <div className="container">
        <form onSubmit={handleSubmit}>
          <p className="heading_interestedinpurchase">
            <b> We are interested in purchasing your vacant land.</b> To get
            started, simply fill out the questionnaire below and click the{" "}
            <b>“Send Me a Cash Offer”</b> button. We will contact you soon to
            find out more about your land.
          </p>
          <p className="require_para">requires field*</p>

          <div>
            <p className="acquire_property_para">
              How did you acquire the property?*
            </p>

            <label className="container1">
              Purchased
              <input
                d
                type="checkbox"
                value="Purchased"
                checked={acquisitionMethod === "Purchased"}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>

            <label className="container1">
              Inherited
              <input
                type="checkbox"
                value="Inherited"
                checked={acquisitionMethod === "Inherited"}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>

            <label className="container1">
              Gifted
              <input
                type="checkbox"
                value="Gifted"
                checked={acquisitionMethod === "Gifted"}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>

            <label className="container1">
              Probate Needed
              <input
                type="checkbox"
                value="Probate Needed"
                checked={acquisitionMethod === "Probate Needed"}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>

            <label className="container1">
              Tax Sale
              <input
                type="checkbox"
                value="Tax Sale"
                checked={acquisitionMethod === "Tax Sale"}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>

            <label className="container1">
              Listing Agent for My Real Estate Client
              <input
                type="checkbox"
                value="Listing Agent for My Real Estate Client"
                checked={
                  acquisitionMethod ===
                  "Listing Agent for My Real Estate Client"
                }
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>

            <label className="container1">
              Under Contract with Owner of Record
              <input
                type="checkbox"
                value="Under Contract with Owner of Record"
                checked={
                  acquisitionMethod === "Under Contract with Owner of Record"
                }
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <p className="heading_property_located">
            Where is your property located?
          </p>

          <p className="para_property_located">
            To get started, we need to know the location of your property.
            Please tell us what state, county, city and zip code the vacant land
            you wish to sell is in.
          </p>
          <hr style={{ color: "black", backgroundColor: "black", height: 1 }} />

          <p className="location_heading">Location*</p>

          <select
            value={selectedState}
            onChange={handleSelect}
            className="dropdown_style_state"
            required
          >
            <option value="selectedState">State</option>
            {statesList.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
          <div>
            <input
              className="dropdown_style_state"
              placeholder="Zip"
              required
              value={zipcode}
              onChange={handlezipcodeChange}
            />
          </div>

          <div>
            <select
              value={selectedCounty}
              onChange={handleCountyChange}
              required
              className="dropdown_style_state"
            >
              <option value="">County</option>
              {counties.map((county, index) => (
                <option key={index} value={county}>
                  {county}
                </option>
              ))}
            </select>
          </div>

          <div>
            <select
              value={selectedCity}
              required
              onChange={handleCityChange}
              className="dropdown_style_state"
            >
              <option value="">City</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>

            <p className="apn_heading">APN – Parcel ID</p>

            <p className="apn_para">
              Please enter your parcel tax ID. This helps us find it in the
              county. It should be on your tax bill.
            </p>
            <input
              className="input_apn"
              required
              value={apnid}
              onChange={handleapnidChange}
            />

            <div>
              <p className="do_you_have_more_para">
                Do you have more than one property to sell?*
              </p>

              <label className="container1">
                Yes
                <input
                  type="checkbox"
                  value="Yes"
                  checked={selectedOption_do_you_have_more_propety === "Yes"}
                  onChange={() => handleOptionChange("Yes")}
                />
                <span className="checkmark"></span>
              </label>

              <label className="container1">
                No
                <input
                  type="checkbox"
                  value="No"
                  checked={selectedOption_do_you_have_more_propety === "No"}
                  onChange={() => handleOptionChange("No")}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <div>
              <p className="do_you_have_more_para">
                Is your vacant Arizona Land in a different state from where you live?*
              </p>

              <label className="container1">
                Yes
                <input
                  type="checkbox"
                  value="Yes"
                  checked={selectedOption_is_your_vacant_land === "Yes"}
                  onChange={() => handle_is_your_vacant_landChange("Yes")}
                />
                <span className="checkmark"></span>
              </label>

              <label className="container1">
                No
                <input
                  type="checkbox"
                  value="No"
                  checked={selectedOption_is_your_vacant_land === "No"}
                  onChange={() => handle_is_your_vacant_landChange("No")}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <div>
              <p className="do_you_have_more_para">
                Do you have more than one property to sell?*
              </p>

              <label className="container1">
                Yes - My land is in a different state from where I currently
                live
                <input
                  type="checkbox"
                  value="Yes"
                  checked={selectedOption_currently_live === "Yes"}
                  onChange={() => handle_currently_live_Change("Yes")}
                />
                <span className="checkmark"></span>
              </label>

              <label className="container1">
                No - I live in the same state as my vacant land
                <input
                  type="checkbox"
                  value="No"
                  checked={selectedOption_currently_live === "No"}
                  onChange={() => handle_currently_live_Change("No")}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <div>
              <p className="do_you_have_more_para">
                What is more important to you?*
              </p>

              <label className="container1">
                A Fast, Hassle-Free Cash Sale
                <input
                  type="checkbox"
                  value="Yes"
                  checked={selectedOption_more_important_to_you === "Yes"}
                  onChange={() => handle_more_important_to_you_Change("Yes")}
                />
                <span className="checkmark"></span>
              </label>

              <label className="container1">
                Full Market Value for My Land
                <input
                  type="checkbox"
                  value="No"
                  checked={selectedOption_more_important_to_you === "No"}
                  onChange={() => handle_more_important_to_you_Change("No")}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <div className="promotionmain_div">
              <div className="d-flex flex-row">
                <label className="container1" htmlFor="agree">
                  Opt in to receive special offers and promotions from us.
                  <input
                    required
                    type="checkbox"
                    id="agree"
                    className="checkbox_input_formgetoffer"
                    name="agree"
                    onChange={handleAgreeChange}
                    checked={agree}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <div className="d-flex flex-row">
                <label className="container1" htmlFor="agree1">
                  I agree to{" "}
                  <Link to="/termsandcondition">terms & conditions</Link> /{" "}
                  <Link to="/privacypolicy">privacy policy</Link> provided by
                  the company. By providing my phone number, I agree to receive
                  text messages from the business.
                  <input
                    required
                    type="checkbox"
                    id="agree1"
                    className="checkbox_input_formgetoffer1"
                    name="agree1"
                    onChange={handleAgree1Change}
                    checked={agree1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <button className="btn_submit_getafreeoffer_form" type="submit">
            {loading ? <Loader /> : "Get my Cash Offer"}
            </button>

            <p className="para_belowsubmitbtn_getoffer">
              By submitting this form and signing up for texts, you consent to
              receive marketing text messages from quick cash for Arizona Land at the
              number provided, including messages sent by auto-dialer. Consent
              is not a condition of purchase. Msg & data rates may apply.
              Unsubscribed at any time by replying STOP or clicking the
              unsubscribe link (where available).
            </p>
          </div>
        </form>
      </div>

      <NotificationDisplay message={notificationMessage} />
    </div>
  );
}

export default GetaFreeOfferForm;
