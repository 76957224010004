import React from 'react'
import "./../TermsandConditionHeader/TermsandConditionHeader.css"
function TermsandConditionHeader() {
  return (
    <div className='main_div_termsheader'>
    <p className='headerterms_para'>TERMS AND CONDITION</p>
  </div>
  )
}

export default TermsandConditionHeader
