import React from "react";
import "./../About247cashoffer/About247cashoffer.css";
import cashoffericon from "./../../../Assets/ABOUT 247CASHOFFER.svg";
function About247cashoffer() {
  return (
    <div className="main_About247cashoffer">
      <div className="container">
        <p className="heading_aboucashoffer">ABOUT QUICK CASH FOR LAND</p>
        <div className="d-flex justify-content-center">
          {/* <img src={cashoffericon} className="cashofferlogoinabout"/> */}
        </div>

        <p className="para_aboutcashoffer">
          At QUICK CASH FOR LAND, we understand the significance of land
          ownership and the importance of ensuring a seamless and ethical
          process for both buyers and sellers. Jennifer Hoffiz started this
          company with a desire to help people mitigate their private land
          holdings. With a dedicated team of professionals including land
          specialists, an attorney, and various real estate professionals we
          strive to uphold the highest standards of integrity in every
          transaction we undertake.
          </p>
       
      </div>
    </div>
  );
}

export default About247cashoffer;
